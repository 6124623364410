@import 'typography';
@import 'mixins';
@import 'shared-table';

body {
    margin: 0;
    background-color: white;
    height: 100vh;
}

p.sub {
    color: hsla(0, 0%, 10%, .7);
    font-weight: 400;
    font-size: 14px;
}

.actions-row {
    @include proportional-spacing(padding, $spacing-xs);
    padding: $spacing-xs;
    background-color: white;
}

.p-sidebar-right.custom-sidebar {
    width: 600px;
    max-width: 600px;
}

div.table-container {
    height: calc(100vh - 200px);
    overflow: auto;
}

.scrollable-page {
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 2rem;
}

/* Estilos para la experiencia de onboarding a pantalla completa */
body.onboarding-active {
  overflow: hidden;
  
  /* Eliminar cualquier padding o margen que pueda interferir */
  margin: 0;
  padding: 0;
  
  /* Asegurar que no aparezcan barras de desplazamiento */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Ocultar cualquier elemento del layout principal que pueda persistir */
  app-root > *:not(app-onboarding-container):not(router-outlet) {
    display: none !important;
  }

  /* Personalización de la barra de progreso para todo el onboarding */
  .progress-bar {
    .progress-fill {
      background: linear-gradient(45deg, #627DEA, #f65aad) !important;
    }
  }

  /* Sobrescribir estilos de PrimeNG */
  .p-progressbar {
    .p-progressbar-value {
      background: linear-gradient(45deg, #627DEA, #f65aad) !important;
    }
  }
}

/* Asegurar que el contenedor de onboarding ocupe toda la pantalla */
app-onboarding-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999; /* Valor alto para asegurar que esté por encima de todo */
}

